@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@fixed-height-slider-cls: ~'@{ra-prefix}-fixed-height-slider';
@fixed-height-slider-min-media-width: 200px;

:export {
  fixedHeightSliderCls: @fixed-height-slider-cls;
  fixedHeightSliderMinMediaWidth: @fixed-height-slider-min-media-width;
}

.@{fixed-height-slider-cls} {
  margin: 0 -@ra-wrapper-horizontal-pad-xs;

  @media only screen and (min-width: @ra-screen-sm) {
    margin: 0 -@ra-wrapper-horizontal-pad-sm;
  }

  @media only screen and (min-width: @ra-screen-md) {
    margin: 0 -@ra-wrapper-horizontal-pad-md;
  }

  @media only screen and (min-width: @ra-screen-lg) {
    margin: 0 -@ra-wrapper-horizontal-pad-lg;
  }

  @media only screen and (min-width: @ra-screen-xl) {
    margin: 0 -@ra-wrapper-horizontal-pad-xl;
  }

  .ra-continuous-slider-item {
    &:first-of-type {
      padding-left: 20px;
    }

    &:last-of-type {
      padding-right: 20px;
    }

    .ra-card-media {
      min-width: @fixed-height-slider-min-media-width;
      text-align: center;
    }
  }
}
